import moment from "moment/moment";
import {
  differenceInYears,
  isBefore,
  parse,
  startOfDay,
  subHours,
} from "date-fns";

// 01/01/1990 => 01-01-1990
export const convertDateFormat = (dateString: string): Date => {
  // Parse the input string with the format "dd/MM/yyyy"
  const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());

  // Return the parsed date object
  return parsedDate;
};

// 01-01-1990 => 01/01/1990
export const formatDateDashesToSlashes = (dateTimeString: string) => {
  let portDate = moment(dateTimeString, "DD-MM-YYYY");
  return portDate.format("DD/MM/YYYY");
};

// 20/03/1990 =>
// ISO 8601: 1990-03-20
export const formatDateSlashesToDashes = (dateTimeString: string) => {
  const dateMoment = moment(dateTimeString, "DD/MM/YYYY");
  return dateMoment.format("YYYY-MM-DD");
};

export const convertDMYtoYMD = (dateString: string) => {
  return moment(dateString, "DD/MM/YYYY").format("YYYY-MM-DD");
};

export const convertYMDtoDMY = (dateString: string) => {
  return moment(dateString, "YYYY-MM-DD").format("DD/MM/YYYY");
};

export const formatCruiseStartDate = (startDate?: string) => {
  return moment(startDate, "DD/MM/YYYY").format("DD MMMM YYYY");
};

export const formatPortDate = (dateTimeString: string) => {
  let portDate = moment(dateTimeString, "DD/MM/YYYY");
  return portDate.format("dddd Do MMMM YYYY");
};

export const formatCartElementDate = (dateTimeString: string) => {
  let date = moment(dateTimeString, "DD/MM/YYYY");
  return date.format("ddd Do MMMM YYYY");
};

export const formatTimeHM = (
  dateTimeString: string | undefined,
  defaultVal?: string
) => {
  if (!dateTimeString) {
    return defaultVal;
  }

  let dateTime = moment(dateTimeString, "DD/MM/YYYY HH:mm");
  return dateTime.format("HH:mm");
};

export const prepareDateTimeForOverlapCheck = (
  dateTimeString: string,
  shouldAdd: boolean
) => {
  const dateTime = moment(dateTimeString, "DD/MM/YYYY HH:mm");

  if (shouldAdd) {
    dateTime.add(29, "minutes");
  } else {
    dateTime.subtract(29, "minutes");
  }

  return dateTime.format("YYYY-MM-DDTHH:mm");
};

// calculate time in format "HH:MM" based on provided
// initial timeString in format "HH:MM" like "13:45"
// duration in format ISO 8601 like "PT3H30M"
// returns time string in format "HH:MM" like "13:45"
export const addDuration = (timeString: string, duration: string) => {
  // time gap between the end of activity and next activity to allow booking
  const TIME_GAP = 30;
  const [hourInNumber, minuteInNumber] = timeString.split(":").map(Number);
  const hoursMatch = duration.match(/(\d+)H/);
  const minutesMatch = duration.match(/(\d+)M/);
  const hoursToAdd = hoursMatch ? parseInt(hoursMatch[1]) : 0;
  const minutesToAdd = minutesMatch ? parseInt(minutesMatch[1]) : 0;

  let newHour = hourInNumber + hoursToAdd;
  let newMinute = minuteInNumber + minutesToAdd + TIME_GAP;

  if (newMinute >= 60) {
    newHour += Math.floor(newMinute / 60);
    newMinute %= 60;
  }

  if (newHour >= 24) {
    newHour %= 24;
  }

  const newTime = `${String(newHour).padStart(2, "0")}:${String(
    newMinute
  ).padStart(2, "0")}`;

  return newTime;
};

export const formatPortTimeTandC = (dateTimeString: string) => {
  let portDate = moment(dateTimeString, "DD/MM/YYYY");
  return portDate.format("YYYY-MM-DD");
};

export const formatDuration = (durationInMinutes: number) => {
  const hourText = durationInMinutes === 60 ? " Hour" : " Hours";
  return +(durationInMinutes / 60).toFixed(2) + hourText;
};

// Converts time from ISO 8601 format like "PT3H30M" into equvalent of "3.5 Hours"
export const convertDurationPeriod = (durationString: string) => {
  // Parse the duration string using moment.duration
  const duration = moment.duration(durationString);

  // Calculate the hours and minutes
  const hours = duration.hours();
  const minutes = duration.minutes();

  // Format the result in exact minutes if less than 1 hour (no hours)
  if (minutes >= 0 && !hours) {
    return minutes === 1 ? `${minutes} Minute` : `${minutes} Minutes`;
  }

  // Format the result in hours (and minutes)
  const halfAnHourInMinutes = 5;

  if (minutes <= 15) {
    // Round hours down
    return hours === 1 ? `${hours} Hour` : `${hours} Hours`;
  } else if (minutes >= 45) {
    // Roung hours up
    return `${hours + 1} Hours`;
  } else {
    // Round hours to half of an hour
    return `${hours}.${halfAnHourInMinutes} Hours`;
  }
};

// Converts time from minutes format like "150" into equvalent of "2.5 Hours"
export const convertDurationPeriodFromMinutes = (minutesProp: number) => {
  if (minutesProp < 60) {
    return minutesProp === 1
      ? `${minutesProp} Minute`
      : `${minutesProp} Minutes`;
  }
  const hours = Math.floor(minutesProp / 60);
  const minutes = minutesProp % 60;

  // Format the result in exact minutes if less than 1 hour (no hours)
  if (minutes >= 0 && !hours) {
    return minutes === 1 ? `${minutes} Minute` : `${minutes} Minutes`;
  }

  // Format the result in hours (and minutes)
  const halfAnHourInMinutes = 5;

  if (minutes <= 15) {
    // Round hours down
    return hours === 1 ? `${hours} Hour` : `${hours} Hours`;
  } else if (minutes >= 45) {
    // Roung hours up
    return `${hours + 1} Hours`;
  } else {
    // Round hours to half of an hour
    return `${hours}.${halfAnHourInMinutes} Hours`;
  }
};

// Converts time from ISO 8601 format like "PT3H30M" into equvalent of "210"
export const convertDurationToMinutes = (durationString: string) => {
  // Parse the duration string using moment.duration and display as minutes (in number type)
  return moment.duration(durationString).asMinutes();
};

export const isCruiseStartDateDenied = (
  cruiseStart: moment.MomentInput,
  isAgency: boolean
) => {
  if (!cruiseStart) {
    return false;
  }

  const cruiseStartMoment = moment(cruiseStart, "DD/MM/YYYY");

  // agency is never denied
  if (isAgency) {
    return false;
  }

  // customers are denied if cruise start is already in the past
  return cruiseStartMoment.isBefore(moment().subtract(1, "days"));
};

export const formatItineraryDepartDate = (
  cruiseDeparture: moment.MomentInput
) => {
  return moment(cruiseDeparture, "DD/MM/YYYY").format("DD MMMM");
};

export const subtractDaysFromDate = (
  date: moment.MomentInput,
  days: moment.DurationInputArg1
) => {
  return moment(date, "DD/MM/YYYY").subtract(days, "days").format("Do MMMM");
};

export const isValidDate = (dateString: string) => {
  const regex =
    /^([1-9]|0[1-9]|[12][0-9]|3[01])\/([1-9]|0[1-9]|1[012])\/(19|20)\d\d$/;
  if (!regex.test(dateString)) {
    return false;
  }

  const date = moment(dateString, "DD/MM/YYYY");

  return date.isValid();
};

export const potentialdmYtoDMY = (dateString: string) => {
  return moment(dateString, "DD/MM/YYYY").format("DD/MM/YYYY");
};

// Formats DD/MM/YYYY to YYYYMMDD -> 03/08/2023 - 20230803
export const formatItineraryDateToOneSpaWorldDate = (dateString: string) => {
  // Returns ['03', '08', '2023']
  const splittedDates = dateString?.split("/");
  // Returns 20230803
  const formattedDate = `${splittedDates[2]}${splittedDates[1]}${splittedDates[0]}`;

  return formattedDate;
};

// Formats date to YYYY-MM-DD - 2023-09-27
export const formatDateToYearMonthDay = (dateString?: string) => {
  // Parse the input date and time using Moment.js
  const parsedDateTime = moment(dateString, "DD/MM/YYYY HH mm");

  // Format the parsed date as "YYYY-MM-DD"
  const formattedDateTime = parsedDateTime.format("YYYY-MM-DD");

  return formattedDateTime;
};

/**
 * Calculate the number of years between two given dates.
 * @param {string} startDate The start date in the format 'yyyy-MM-dd'.
 * @param {string} endDate The end date in the format 'yyyy-MM-dd'.
 * @returns {number} The number of years between the two dates.
 */
export const calculateYearsDifference = (
  startDate: string,
  endDate: string
): number => {
  const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
  const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());
  return differenceInYears(parsedEndDate, parsedStartDate);
};

// Function to convert time string to minutes in number type
export const convertStringTimeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

// check two events and determine if they overlap in time
export const areEventsOverlapping = (
  event1: { startTime: string; endTime: string },
  event2: { startTime: string; endTime: string }
) => {
  const event1StartTime = convertStringTimeToMinutes(event1.startTime);
  const event1EndTime = convertStringTimeToMinutes(event1.endTime);
  const event2StartTime = convertStringTimeToMinutes(event2.startTime);
  const event2EndTime = convertStringTimeToMinutes(event2.endTime);

  if (
    (event1StartTime <= event2StartTime && event1EndTime > event2StartTime) ||
    (event1StartTime < event2EndTime && event1EndTime >= event2EndTime) ||
    (event2StartTime <= event1StartTime && event2EndTime > event1StartTime) ||
    (event2StartTime < event1EndTime && event2EndTime >= event1EndTime)
  ) {
    return true; // Overlapping events
  }

  return false; // Non-overlapping events
};

/**
 * Extracts the minimum and maximum age from a given age range string.
 * @param {string} ageRange The age range in the format "(minAge-maxAge)". Format "(2-12)"
 * @returns {{ minAge: number, maxAge: number }} An object containing the minAge and maxAge as numbers.
 */
export const getAgeRangeInNumbers = (
  ageRange: string
): { minAge: number; maxAge: number } => {
  const range = ageRange.slice(1, -1);
  const [minAge, maxAge] = range.split("-").map(Number);
  return { minAge, maxAge };
};

/**
 * Check if the given number of years falls within the specified age range.
 * @param {number} numberOfYears The number of years.
 * @param {string} ageRange The age range in the format "(minAge-maxAge)". Format "(2-12)"
 * @returns {boolean} True if the number of years is within the specified range, otherwise false.
 */
export const checkIfAgeInRange = (
  numberOfYears: number,
  ageRange: string
): boolean => {
  const { minAge, maxAge } = getAgeRangeInNumbers(ageRange);
  return numberOfYears >= minAge && numberOfYears <= maxAge;
};

/**
 * Check if time string is after other time string
 * @param {string} time1 is being checked if is after (time format "19:30")
 * @param {string} time2 time to check to (time format "19:30")
 * @returns {boolean} True if time is after, false if before or equal
 */
export const _isAfter = (time1: string, time2: string): boolean => {
  const timeFormat = /^\d{2}:\d{2}$/;

  // Validate time format
  if (!timeFormat.test(time1) || !timeFormat.test(time2)) {
    console.error("Time must be in format HH:mm");
    return true;
  }

  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);

  // Validate hours and minutes
  if (
    hours1 < 0 ||
    hours1 > 23 ||
    minutes1 < 0 ||
    minutes1 > 59 ||
    hours2 < 0 ||
    hours2 > 23 ||
    minutes2 < 0 ||
    minutes2 > 59
  ) {
    console.error(
      "Hours must be in range of 00-23, and minutes must be in range of 00-59"
    );
    return true;
  }

  if (hours1 > hours2) return true;
  if (hours1 < hours2) return false;
  return minutes1 > minutes2;
};

export const isBeforeHoursFromDate = (hours: number, dateStr: string) => {
  // Parse the given date string (assuming format "dd/MM/yyyy")
  const givenDate = parse(dateStr, "dd/MM/yyyy", new Date());

  // Get the start of the day for the given date
  const startOfGivenDate = startOfDay(givenDate);

  // Subtract the given hours from the start of the date
  const thresholdTime = subHours(startOfGivenDate, hours);

  // Get the current time
  const now = new Date();

  // Check if the current time is before the threshold
  return isBefore(now, thresholdTime);
};
